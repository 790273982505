@font-face {
    font-family: 'ProductSans';
    src: url("./assets/fonts/Black.ttf");
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;300;400;500;600;700;800&family=DM+Serif+Text&display=swap');

* {
    font-family: 'Be Vietnam Pro', sans-serif;
}

$white: #fff;
$black: #000000;

:root {
    /* Main brand colors */
    --primary-color: #D2FFAA;
    --secondary-color: #638942;
    --bg-contrast: #003322;
    --greenWhite: #F5FFEB;
    --white: #ffffff;
    --interact-object-color: #D17A22;
    --linkText: #60A130;
}

.clr_link {
    color: var(--linkText);
    cursor: pointer;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #F5FFEB;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #023C40;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.linkClrn {
    color: #60A130 !important;
}

.txt_dcr_none { 
    text-decoration: none !important;
    text-transform: none !important;
}

.p-0 {
    padding: 0;
}

.mr-1 {
    margin-right: 1rem;
}

.align-items-start {
    align-items: flex-start;
}

.cursor-pointer {
    cursor: pointer;
}

.fs-1pt5 {
    font-size: 1.5rem;
}



.form-control {
    font-size: 1vw;
    @media only screen and (min-width: 1024px) {
        font-size: 16px;
    }
    @media only screen and (max-width: 560px) {
        font-size: 5vw;
    }
}

.fluidContainer {
    max-width: 100%;

    &.PanelHeader {
        .navbar-expand-lg {
            padding: 0;
        }

        &.container {
            .container {
                max-width: 100%;
            }
        }
    }
}

.hide {
    display: none !important;
}

.nodatafound {
    font-size: 20px;
    margin: 20px 0;
}
.GeneralTableContainer .MuiTableCell-root {
    font-family: 'Be Vietnam Pro', sans-serif;
}

a {
    color: var(--linkText);
    text-decoration: none;

    &:hover {
        color: var(--bg-contrast)
    }
}

.primary-btn {
    background-color: var(--bg-contrast);
    color: var(--white);
    border-radius: 25px;
    display: inline-block;
    width: 200px;
    height: 50px;
    border: none;
    font-size: 1vw;
    @media only screen and (min-width: 1024px) {
        font-size: 16px;
    }
    @media only screen and (max-width: 560px) {
        font-size: 5vw;
    }
    &:hover {
        background: #52960B;
        color: var(--white)
    }
}
.social-btn {
    background-color: rgb(61, 133, 240);
    color: var(--white);
    border-radius: 25px;
    display: inline-block;
    // width: 200px;
    height: 50px;
    border: none;
    font-size: 1vw;
    @media only screen and (min-width: 1024px) {
        font-size: 16px;
    }
    @media only screen and (max-width: 560px) {
        font-size: 5vw;
    }
    &:hover {
        background: dodgerblue;
        color: var(--white)
    }
}
.export-btn{height: 1.5vw;width: 50px;color: $white;
    &:hover{ 
        background: #52960B;
        color: var(--white)
    }
}

.error-btn {
    background-color: #d95151;
    color: #272121;

    &:hover {
        background: #e66c6c;
        color: var(--black)
    }
}

.secondary-btn {
    background-color: var(--secondary-color);
    color: var(--white);
    border-radius: 25px;
    display: inline-block;
    width: 200px;
    height: 50px;
    line-height: normal;
    border: none;
    font-size: 1vw;
    @media only screen and (min-width: 1024px) {
        font-size: 16px;
    }
    @media only screen and (max-width: 560px) {
        font-size: 5vw;
    }
    &:hover {
        background: #52960B;
        color: var(--white)
    }
}

.AuthPage {
    background: url(./assets/images/mainbg.jpg) no-repeat top center / 100% 100%;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 20px 0;

    .row {
        background-color: rgba(255, 255, 255, 0.65);
        border-radius: 10px 30px 30px 10px;
        overflow: hidden;

        .AuthRightImage {
            &.loginPage {
                img {
                    max-width: 60%;
                }
            }

            padding: 30px 100px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img {
                max-width: 100%;
            }

            h5 {
                color: var(--white);
                font-size: 30px;
                margin: 20px 0;
                font-weight: 100;
            }
        }

        .AuthLeft {
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;

            .AuthLeftContent {
                padding: 30px 100px;
                width: 100%;

                h4 {
                    font-weight: 500;
                }

                .AuthHeaderLogo {
                    border-bottom: 1px solid #ddd;
                    padding-bottom: 10px;
                    width: 100%;
                    display: block;
                    margin-bottom: 20px;

                    img {
                        max-height: 50px;
                    }
                }
            }
        }
    }

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .row {
            -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    @media only screen and (max-width: 990px) {
        height: auto;
        min-height: 100vh;

        .row {
            border-radius: 30px;
            margin: 2px;

            .AuthLeft {
                order: 2;

                .AuthLeftContent {
                    padding: 15px;
                }
            }

            .AuthRightImage {
                padding: 20px;
            }
        }

        &.LoginPage {
            .AuthRightImage {
                display: none;
            }
        }

        .LoginPage .row:last-child {
            display: none;
        }
    }


}

/*website*/
.MainHeader {
    padding: 0.3vw 0;
    border-bottom: 1px solid #ddd;

    .MainLogo img {
        max-height: 60px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            float: left;
            margin-left: 2vw;

            a {
                color: #333;
                font-size: 1vw;
                line-height: 1.5vw;
                @media only screen and (min-width: 1024px) {
                    font-size: 16px;
                }
                @media only screen and (max-width: 560px) {
                    font-size: 5vw;
                }
                &:hover,
                &.active {
                    color: var(--bg-contrast)
                }
            }

            .header-btn-outline {
                border: 1px solid #d6d6d6;
                border-radius: 2.5vw;
                padding: 10px 2.5vw;

                &:hover {
                    background: var(--bg-contrast);
                    color: $white;
                }
            }
        }
    }

    .PanelHeader {
        .MainLogo img {
            max-height: 3vw;
        }

        @media only screen and (max-width: 1024px) {
            .MainLogo img {
                max-height: 35px;
            }
        }
    }
}

.MainHeader,
.DashboardHeader {
    .HeaderProfileSection {
        display: flex;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: 1024px) {
            padding: 10px 0;
        }
        .btn {
            background: none;
            border: none;
            padding: 0;
            color: #333;
            line-height: 3vw;
            margin-left: 8px;
            font-size: 1vw;
            @media only screen and (min-width: 1024px) {
                font-size: 16px;
            }
            @media only screen and (max-width: 560px) {
                font-size: 5vw;
            }
        }

        .dropdown-menu {
            a {
                font-size: 1vw;
                @media only screen and (min-width: 1024px) {
                    font-size: 16px;
                    line-height: normal;
                }
                @media only screen and (max-width: 560px) {
                    font-size: 5vw;
                    line-height: normal;
                }
                &:active {
                    background: #ddd;
                }
            }
        }

        .HeaderProfileImage {
            width: 2.5vw;
            height: 2.5vw;
            border-radius: 100%;
            overflow: hidden;
            @media only screen and (max-width: 1024px) {
                width: 35px;
                height: 35px;
            }
            .sb-avatar.sb-avatar--src {
                float: left;
                width: 100% !important;
                height: 100% !important;

                img {
                    width: 100% !important;
                    height: 100% !important;
                    vertical-align: baseline;
                }
            }

            .sb-avatar.sb-avatar--text,
            .sb-avatar__text {
                width: 100% !important;
                height: 100% !important;
            }

            img {
                max-width: 100%;
            }
        }
    }

    .HeaderButtonSection {
        .btn {
            border: 1px solid #d6d6d6;
            border-radius: 40px;
            padding: 10px 26px;
            background: none;
            color: black;
        }
    }
}

.UserPanel {
    .DashboardHeader {
        padding: 5px 15px;
        border-bottom: 1px solid #ddd;

        .MainLogo img {
            max-height: 50px;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                float: left;
                margin-left: 30px;

                a {
                    color: #333;
                    font-size: 18px;

                    &:hover,
                    &.active {
                        color: var(--bg-contrast)
                    }
                }

                .header-btn-outline {
                    border: 1px solid #d6d6d6;
                    border-radius: 2.5vw;
                    padding: 10px 2.5vw;

                    &:hover {
                        background: var(--bg-contrast);
                        color: $white;
                    }
                }
            }
        }
    }

    .GeneralContainer {
        display: flex;

        .LeftSideContainer {
            width: 16vw;
            float: left;
            border-right: 1px solid #ddd;
            min-height: calc(100vh - 4vw);

            .SideBarProfile {
                text-align: center;
                padding: 1.2vw 0;
                border-bottom: 1px solid #ddd;

                .ProfileImage {
                    width: 8vw;
                    height: 8vw;
                    border-radius: 100%;
                    overflow: hidden;
                    display: inline-block;

                    .sb-avatar.sb-avatar--src {
                        width: 100% !important;
                        height: 100% !important;

                        img {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }

                    span {
                        font-size: 3.5vw;
                    }
                }

                h4 {
                    font-size: 1.3vw;
                    color: var(--secondary-color);
                    font-weight: bold;
                    margin: 10px 0 0 0;
                    @media only screen and (max-width: 1024px) {
                        font-size: 12px;
                        line-height: normal;
                    }
                }

                p {
                    font-size: 1vw;
                    color: #333;
                    @media only screen and (min-width: 1024px) {
                        font-size: 16px;
                    }
                    @media only screen and (max-width: 560px) {
                        font-size: 5vw;
                    }
                }

                a {
                    font-size: 0.8vw;
                    @media only screen and (max-width: 1024px) {
                        font-size: 12px;
                        line-height: normal;
                    }
                }
            }

        }

        .RightMainContainer {
            width: 84vw;
            float: left;
            height: calc(100vh - 4vw);
            overflow: auto;

            @media only screen and (max-width: 578px) {
                width: 100%
            }

            .Right-Side-Main-Container {
                min-height: calc(100vh - 112px);
                width: 100%;
                display: block;
                padding: 10px 15px;
            }

            .changePasswordPage {
                width: 100%;
                padding: 100px 0;

                form {
                    width: 800px;
                    @media only screen and (max-width: 767px) {
                        width: 100%;
                    }
                    .form-control{
                        font-size: 16px;
                    }
                }
            }
        }

        @media only screen and (max-width: 767px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .card-wrapper {
        background: url(../src/assets/images/website/bg-flows.png) no-repeat bottom center / cover;
        background-color: transparent;
        border: 0;
        border-radius: 20px;
        padding: 6px 10px;

        h1 {
            font-size: 25px;
            font-weight: 300;
            color: #ffffff;
        }

        h3 {
            font-size: 25px;
            font-weight: 300;
            color: #ffffff;
        }

        .card-appointments {
            h3 {
                font-size: 30px;
                margin: 0;
            }

            h4 {
                font-size: 14px;
                color: #ffffff;
            }

            h6 {
                font-size: 10px;
                color: #ffffff;
                margin: 0px;
            }

            @media only screen and (max-width: 1440px) {
                h3 {
                    font-size: 25px;
                }
            }
        }

        .card {
            min-height: 70px;
            color: #ffffff;
            width: 100%;
            margin-bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 240px;
            padding: 5px 10px;

            @media only screen and (max-width: 767px) {
                justify-content: center;
                align-items: center;
                width: 100%;

            }
        }

        svg {
            font-size: 35px;
            color: #aebca3;
        }

        @media only screen and (max-width: 1681px) {

            h1,
            h3 {
                font-size: 20px;
            }
        }

        @media only screen and (max-width: 1440px) {

            h1,
            h3 {
                font-size: 18px;
            }

            svg {
                font-size: 30px;
            }
        }

        @media only screen and (max-width: 1199px) {
            padding: 7px 10px;
        }

        @media only screen and (max-width: 767px) {
            .card-body {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .text-start {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    .quick-list-items {
        background-color: #f8fcf3;
        box-shadow: 0px 3px 5px 0px #c1c1c1;
        border-radius: 20px;
        padding: 7px 0;

        .card {
            background-color: transparent;
            border: 0;
        }

        @media only screen and (max-width: 1681px) {
            a {
                font-size: 14px;
            }
        }

        @media only screen and (max-width: 1440px) {
            a {
                font-size: 15px;
            }

            .card-body {
                @media only screen and (max-width: 1199px) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        @media only screen and (max-width: 991px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .card {
        @media only screen and (max-width: 767px) {
            margin-bottom: 20px;
        }
    }

    .connect-icon {
        svg {
            background-color: #ffffff;
            box-shadow: 0px 2px 2px 1px #c3c3c3;
            border-radius: 18px;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 6px;
            min-width: 30px;
            margin: 0;

            @media only screen and (max-width: 1681px) {
                padding: 4px;
                min-width: 25px;
            }
        }

        @media only screen and (max-width: 1440px) {
            svg {
                padding: 5px;
                min-width: 30px;
            }
        }
    }

    .contact-info {
        p {
            font-size: 14px;
            background-color: #e2f7d1;
            margin-right: 20px;
            padding: 5px 25px 5px 15px;
        }

        @media only screen and (max-width: 1681px) {
            p {
                margin-right: 5px;
                padding: 5px 20px 5px 10px;
            }
        }

        @media only screen and (max-width: 1440px) {
            p {
                padding: 5px 15px 5px 15px;
                margin: 0px;
            }
        }

        @media only screen and (max-width: 1199px) {
            p {
                padding: 5px 15px 5px 15px
            }
        }

        @media only screen and (max-width: 991px) {
            p {
                padding: 5px 25px 5px 25px;
            }
        }

        @media only screen and (max-width: 767px) {
            p {
                margin-top: 0px !important;
            }
        }
    }

    .contact-content {
        background-color: #f8fcf3;
        box-shadow: 0px 3px 5px 0px #c1c1c1;
        border-radius: 20px;
        text-align: left;

        p {
            margin-bottom: 0;
            font-size: 12px;
        }

        div {
            @media only screen and (max-width: 991px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        @media only screen and (max-width: 1440px) {
            p {
                font-size: 15px;
            }
        }

        @media only screen and (max-width: 1199px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        @media only screen and (max-width: 991px) {
            margin-top: 20px;
        }

        @media only screen and (max-width: 767px) {
            margin-top: 0px;

            p {
                margin-top: 10px;
            }
        }
    }

    .rdrMonth {
        width: 100%;
    }

    // .bg-success {
    //     background-color: #5d7946 !important;box-shadow: 0px 3px 5px 0px #3e3e3e;border-radius: 20px;padding: 10px 20px;display: flex;flex-direction:column;justify-content: center; align-items: center;width: 100%;max-width: 120px;
    // }
    .bg-danger {
        border-radius: 5px;
    }

    .collapse {
        .dropdown-menu {
            &.show {
                right: 0px;
                left: auto;
                top: 3vw;
            }
        }
    }

    @media only screen and (max-width: 991px) {
        .menu-btn {
            display: flex;
            flex-direction: column;
            align-items: flex-end !important;
        }

        .reportRow {
            gap: 0.5em 0;
        }

        .myappntRow {
            gap: 0.5em 0;
        }
    }

    @media only screen and (max-width: 767px) {
        .css-rorn0c-MuiTableContainer-root {
            padding-bottom: 2.5vw;
        }

        // .UserPanelContainer {
        //     // .mt-4 {margin: 0px !important;}
        //     .mb-4 {
        //         margin-bottom: 0px !important;
        //     }
        // }

        .profileRow {
            gap: 0.5em;
            margin-top: 5px;
        }
    }

    .contact-info {
        margin-top: 10px;
    }


}

.reportRow {
    justify-content: space-around;

    .reportCard {
        background: #fff;
    }
}

.dashboard-card {
    background: url(./assets/images/mainbg.jpg);

    h1 {
        font-size: 2vw;
        font-weight: 400;
        color: #ffffff;
    }

    h3 {
        font-size: 2.3vw;
        font-weight: 400;
        color: var(--white);
    }

    h6 {
        color: #ffffff;
    }

    svg {
        color: #aebca3;
        font-size: 3vw;
        margin-right: 0.7vw;
        @media only screen and (max-width: 1024px) {
            font-size: 30px;
        }
    }

    .card-block {
        min-height: 8vw;
    }

    .card-body-wrapper {
        padding: 0px 1.5vw;

        .bg-success {
            max-width: 200px;
        }

        @media only screen and (max-width: 767px) {
            .row {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 16px;
            }
        }
    }

    .card-title {
        h3 {
            font-size: 1.3vw;
            margin: 0;
        }
    }

    @media only screen and (max-width: 1440px) {
        h3 {
            font-size: 22px;
        }

        .card-title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
    }

    .card-block {
        h3 {
            color: var(--secondary-color);
        }

        h6 {
            color: var(--secondary-color);
        }

        h5 {
            color: var(--secondary-color);
        }
    }
}

.GeneralTable {
    background: var(--greenWhite);
    border-radius: 0;
    overflow: hidden;
    display: block;
    box-shadow: 0px 0px 1px #52960B;
    padding: 1vw;
    margin: 0px;

    @media only screen and (max-width: 578px) {
        padding: 10px;
    }

    .GeneralTableHeader,
    .GeneralHeader {
        .arrow-btn svg {
            width: 26px;
            height: 26px;
        }

        h2 {
            font-size: 16px;
            font-weight: bold;
            color: var(--secondary-color);
            @media only screen and (max-width: 1024px) {
                font-size: 16px;
            }
        }
    }

    .GeneralTableContainer,
    .GeneralInnerContainer {
        background: #fff;
        border-radius: 0;
        overflow: hidden;
        box-shadow: 0 2px 5px #b7e784;
        margin: 1vw 0 20px 0;
        padding: 20px 0px;
        
        table {
            tr {
                th {
                    font-weight: bold;
                    font-size: 1vw;
                    color: var(--linkText);
                    line-height: 1.5vw;
                    padding: 1vw;
                    text-transform: capitalize;

                    @media only screen and (min-width: 1024px) {
                        font-size: 16px;
                        line-height: normal;
                    }
                    @media only screen and (max-width: 560px) {
                        font-size: 4vw;
                        line-height: normal;
                    }
                }

                td {
                    font-size: 1vw;
                    line-height: 1.5vw;
                    padding: 1vw;
                    @media only screen and (min-width: 1024px) {
                        font-size: 16px;
                    }
                    @media only screen and (max-width: 560px) {
                        font-size: 5vw;
                    }
                    a{
                        text-transform: capitalize;
                        }

                    @media only screen and (max-width: 1024px) {
                        font-size: 15px;
                        line-height: normal;
                    }
                }
            }
        }

        .row {
            min-width: 100%;
            padding: 0 15px;
            @media only screen and (max-width: 640px) {
                flex-flow: column;
            }
            .col-md-3{
                @media only screen and (max-width: 1024px) {
                    width: 100%;
                }
            }
        }

        .upcomcard-body {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-size: 1vw;
            @media only screen and (min-width: 1024px) {
                font-size: 16px;
            }
            @media only screen and (max-width: 560px) {
                font-size: 5vw;
            }
            &:hover {
                background-color: #69BA13;
                color: white;
            }
        }

        .upcomcard-content {
            display: flex;
            text-transform: capitalize;
            flex-direction: column;

            span {
                font-size: 1vw;
                @media only screen and (max-width: 1024px) {
                    font-size: 12px;
                }
                @media only screen and (min-width: 1024px) {
                    font-size: 16px;
                }
                @media only screen and (max-width: 560px) {
                    font-size: 5vw;
                }
            }
        }

        .chart-svg {
            canvas {
                width: 100% !important;
            }
        }
    }

    .GeneralTableSearch {
        padding: 0 0px 15px 0px;
        border-bottom: 1px solid var(--linkText);

        button {
            height: 2.5vw;
            @media only screen and (max-width: 1024px) {
                height: 50px
            }

            &:hover {
                background-color: #0e6d29;
            }
        }

        input {
            height: 2.5vw;
            padding: 0 1.2vw;
            font-size: 1vw;
            @media only screen and (max-width: 1024px) {
                font-size: 1vw;
                height: 45px;
                padding: 0 20px;
            }
            @media only screen and (min-width: 1024px) {
                font-size: 16px;
            }
            @media only screen and (max-width: 560px) {
                font-size: 5vw;
            }
            &:focus,
            &:active {
                outline: none;
            }
        }

        .MuiTextField-root {
            border: none;
            width: 100%;

            .MuiTextField-root fieldset {
                border-radius: 22px;
                border: none;
                height: 39px;
            }

            @media only screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 20px;
            }
        }

        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: var(--linkText);
            border-radius: 22px;
        }

        input.data-range-picker {
            border: 1px solid var(--linkText);
            border-radius: 20px;
            height: 2.5vw;
            padding: 0 20px;
            @media only screen and (max-width: 1024px) {
                font-size: 16px;
                height: 45px;
            }

            &:focus {
                border: 1px solid var(--linkText);
                box-shadow: none;
            }
        }

        @media only screen and (max-width: 767px) {
            padding: 10px 10px 20px;

            .btn-menubar {
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                margin-top: 20px;
                @media only screen and (max-width: 767px) {
                    margin-top: 0;
                }
            }

            .btn-search {
                margin: 30px 0px 10px;
                justify-content: center;
            }

            // button {height: 35px;padding: 0 30px;font-size: 13px;}
            .btn-search {
                button {
                    padding: 0 35px !important;
                    height: 35px;
                    font-size: 13px;
                }
            }

            .btn-special {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 30px 0 10px;
                flex-flow: row;
            }

            button {
                height: 50px;
                margin-bottom: 20px;
                @media only screen and (max-width: 767px) {
                    height: 40px;
                    line-height: 40px;
                    margin-top: 10px;
                    width: 48%;
                    color: #fff;
                    background: #60a130;
                    border-radius: 15px;
                    font-size: 16px;
                }
            }
        }
    }

    @media only screen and (max-width: 1199px) {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    @media only screen and (max-width: 767px) {
        margin-top: 5px;
        margin-bottom: 30px;
    }

    .btn-menubar {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

}

.specialistOnboardingForm {
    padding: 0 200px;
}

.GeneralContentBg {
    background: var(--greenWhite);
    border-radius: 20px;
    overflow: hidden;
    display: block;
    box-shadow: 0px 0px 1px #52960B;
    padding: 20px 30px;
    margin: 20px 0;

    .GeneralContentBgInner {
        background: var(--white);
        border-radius: 20px;
        overflow: hidden;
        width: 100%;
        padding: 30px;
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0 2px 5px #69BA13;
        margin: 30px 0 20px 0;
        @media only screen and (max-width: 560px) {
            padding: 20px;
        }

        .generalForm {
            .col-md-12 {
                margin-bottom: 30px;
            }

            .MuiTextField-root {
                min-width: 100%;

                .MuiOutlinedInput-root {
                    border-radius: 30px;

                    .MuiInputBase-input {
                        padding: 10px 15px;
                    }
                }
            }
        }
    }
}

.SideBarMenu {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            border-bottom: 1px solid #ddd;

            &.menuActive {
                background: var(--secondary-color);

                a {
                    color: #fff;

                    svg {
                        fill: var(--white);

                        path {
                            fill: var(--white)
                        }
                    }
                }
            }

            a {
                padding: 1vw 1.2vw;
                display: block;
                font-size: 1vw;
                line-height: 1.5vw;
                color: #333;
                font-weight: bold;
                @media only screen and (max-width: 1024px) {
                    font-size: 15px;
                    line-height: normal;
                    padding: 15px;
                }
                @media only screen and (min-width: 1024px) {
                    font-size: 16px;
                }
                @media only screen and (max-width: 560px) {
                    font-size: 5vw;
                }
                svg {
                    width: 1.3vw;
                    height: 1.3vw;
                    fill: var(--secondary-color);
                    margin-right: 1vw;
                    display: inline-block;
                    vertical-align: top;
                    @media only screen and (max-width: 1024px) {
                        width: 15px;
                        height: 15px;
                    }
                    @media only screen and (min-width: 1024px) {
                        font-size: 16px;
                    }
                    path {
                        fill: var(--secondary-color)
                    }
                }
            }
        }
    }
}

.GeneralInnerContainer {
    padding: 20px;
}

.ProfileImage {
    width: 8vw;
    height: 8vw;
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid #43a100;
    ;

    .sb-avatar.sb-avatar--src {
        width: 100% !important;
        height: 100% !important;

        img {
            width: 100% !important;
            height: 100% !important;
        }
    }

    .sb-avatar.sb-avatar--text {
        width: 100% !important;
        height: 100% !important;

        .sb-avatar__text {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

.ProfileLabels {
    h3 {
        font-size: 1.5vw;
        margin-bottom: 1vw;
        font-weight: bold;
        color: #333;
        @media only screen and (min-width: 1024px) {
            font-size: 16px;
        }
        @media only screen and (max-width: 560px) {
            font-size: 5vw;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin-bottom: 10px;
            width: 100%;
            float: left;

            label {
                width: 35%;
                float: left;
                position: relative;
                font-weight: normal;
                font-size: 0.9vw;
                position: relative;
                font-weight: bold;
                @media only screen and (max-width: 1024px) {
                    font-size: 12px; 
                }

                &:after {
                    content: ":";
                    float: unset;
                    margin-left: 10px;
                }
            }

             > span {
                float: left;
                position: relative;
                word-wrap: break-word;
                font-size: 0.9vw;
                color: var(--secondary-color);
                width: calc(100% - 35%);
                @media only screen and (max-width: 1024px) {
                    font-size: 12px;
                }

               
            }
            .MuiSwitch-root {
                width: 58px;
            }
        }
    }

    .row {
        margin-bottom: 20px;

        label {
            width: 100%;
            float: left;
            position: relative;
            font-weight: normal;
            font-size: 1vw;
            font-weight: bold;
            @media only screen and (min-width: 1024px) {
                font-size: 16px;
            }
            @media only screen and (max-width: 560px) {
                font-size: 4vw;
            }
        }

        span {
            width: 60%;
            float: left;
            position: relative;
            font-size: 1vw;
            color: var(--secondary-color);
            @media only screen and (min-width: 1024px) {
                font-size: 16px;
            }
            @media only screen and (max-width: 560px) {
                font-size: 4vw;
            }
            
        }
    }

    .MuiTableContainer-root {
        border-radius: 0px;
        border: 1px solid #43a100;
        ;
        ;

        th,
        td {
            max-width: 200px;
            word-wrap: break-word;
        }
    }

    .badge {
        line-height: 1vw;
        border-radius: 1vw;
        padding: 5px 1vw;
        margin-bottom: 5px;
        background: var(--primary-color) !important;
        color: var(--secondary-color);
        font-size: 0.8vw;
        @media only screen and (max-width: 1024px) {
            font-size: 12px;
        }
    }
}

.generalBorderWithRadius {
    border-radius: 0;
    padding: 1vw 0.5vw;
    border: 1px solid #43a100;
    ;
}

.footer-copyrights {
    p {
        font-size: 12px;
    }
}

.AppointmentBookingOuter {
    background: var(--greenWhite);
    border-radius: 30px;
    padding: 30px;
    max-width: 75%;
    margin: 50px 0;
    position: relative;
    min-height: 700px;
    @media only screen and (max-width: 1024px) {
        max-width: 100%;
        padding: 15px;
    }

    .row {
        max-width: 80%;
        @media only screen and (max-width: 1024px) {
            max-width: unset;
        }
    }

    .primary-btn{
        @media only screen and (max-width: 1024px) {
            margin: 0 0 32px 0;
        }
    }

    .AppointmentCalender,
    .AppointmentCalenderTimeSlot,
    .AppointmentCalenderTextfield {
        background: #fff;
        border-radius: 15px;
        box-shadow: 0 0 10px var(--linkText);
    }

    .AppointmentCalenderTimeSlot {
        padding: 20px;
        min-height: 333px;
        @media only screen and (max-width: 640px) {
            margin-top: 30px;
            min-height: unset;
        }

        h4 {
            font-size: 20px;
        }
    }

    .AppointmentCalenderTextfield {
        padding: 20px;
    }

    .AppointmentBookingDoctorInfo {
        width: 280px;
        height: 550px;
        border: 1px solid var(--greenWhite);
        box-shadow: 0 0 5px var(--primary-color);
        border-radius: 30px;
        position: absolute;
        right: -10%;
        top: 60px;
        overflow: hidden;
        @media only screen and (max-width: 1024px) {
            position: static;
            width: 50%;
        }
        @media only screen and (max-width: 640px) {
            position: static;
            width: 100%;
        }
        .AppointmentBookingDoctorImage {
            width: 280px;
            height: 350px;
            float: left;
            background: #fff;
            @media only screen and (max-width: 1024px) {
                width: 100%;
                height: unset;
            }

            .sb-avatar__text {
                height: 350px !important;
            }

            .sb-avatar.sb-avatar--src {
                width: 100% !important;
                height: 350px !important;
                text-align: center;
                @media only screen and (max-width: 1024px) {
                    //width: unset;
                    height: unset;
                    text-align: center;
                }

                img {
                    width: auto !important;
                    height: 100%;
                    @media only screen and (max-width: 1024px) {
                        width: auto !important;
                        height: 100% !important;
                    }
                }
            }

        }

        .AppointmentBookingDoctorContent {
            background-color: rgba(255, 255, 255, 0.5);
            float: left;
            width: 100%;
            padding: 20px;
            height: 200px;

            ul {
                list-style: none;
                display: flex;
                justify-content: space-between;
                margin: 30px 0 0 0;
                padding: 0;

                li {
                    float: left;
                    border: 1px solid #ddd;
                    width: 24px;
                    height: 24px;
                    border-radius: 100%;
                    text-align: center;
                    line-height: 20px;
                    font-size: 12px;
                    line-height: 24px;
                }
            }
        }
    }

}

.paycard {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    @media only screen and (max-width: 640px) {
        flex-direction: column;
    }
}

.payment-form {
    width: 90%;
    display: flex;
    padding: 18px;
    border-radius: 1vw;
    border: 2px solid green;
    box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
    flex-direction: column;
    @media only screen and (min-width: 1024px) {
        font-size: 16px;
    }
}

.pagination {
    li {
        margin: 0 0.5vw;

        a {
            border: 1px solid #ddd;
            width: 40px;
            height: 40px;
            background: #fff;
            border-radius: 100%;
            display: block;
            text-align: center;
            line-height: 35px;
            font-size: 1vw;
            @media only screen and (min-width: 1024px) {
                font-size: 16px;
            }
            @media only screen and (max-width: 560px) {
                font-size: 5vw;
            }
        }

        &.previous,
        &.next {
            a {
                width: auto;
                border-radius: 15px;
                padding: 0 20px;
                height: 35px;
                line-height: 35px;
                font-size: 1vw;
                @media only screen and (min-width: 1024px) {
                    font-size: 16px;
                }
                @media only screen and (max-width: 560px) {
                    font-size: 5vw;
                }
            }
        }

        &.active a {
            background: var(--linkText);
            color: var(--white);
        }

        @media only screen and (max-width: 575px) {
            margin: 0 5px;
        }
    }
}

.AppointmentCard {
    label {
        font-size: 0.8vw;
        font-weight: bold;
        color: var(--secondary-color);
    }

    span {
        font-size:  0.8vw;
        font-weight: bold;
        color: #333;
        @media only screen and (max-width: 560px) {
            font-size: 4vw;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            float: left;
            margin-bottom: 20px;
            width: 100%;

            label {
                width: 45%;
                float: left;
                position: relative;

                @media only screen and (max-width: 560px) {
                    width: 100%;
                    font-size: 4vw;
                    &:after {
                        display: none;
                    }
                }

                &:after {
                    content: ":";
                    float: right;
                    margin-right: 10px;
                }
            }

            span {
                width: 55%;
                float: left;
            }
        }
    }
}

.filterSection {
    padding: 10px 0;
    float: left;
    width: 100%;
}

.cancelBtn {
    background: #ff3939;
    border-radius: 30px;
    padding: 10px 30px;
    border: none;
    color: #fff;
    cursor: pointer;

    &:disabled {
        opacity: 0.3;
    }
}

.successBtn {
    background: var(--secondary-color);
    border-radius: 30px;
    padding: 10px 30px;
    border: none;
    color: #fff;
    cursor: pointer;

    &:disabled {
        opacity: 0.3;
    }
}

.DoctorsListWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.conact-us-content {
    background: url(./assets/images/mainbg.jpg) no-repeat top center / 100% 100%;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .contact-us-form-content {
        background-color: rgba(255, 255, 255, 0.842);
        border-radius: 15px;
        padding: 10px;
        padding-top: 1vw;
        width: 40%;
        @media only screen and (min-width: 1024px) {
            font-size: 16px;
        }
    }

    .contact-us-header {
        color: #064405a7;
        text-align: center;
    }

    .contact-us-btn {
        background: #003322;
        border-radius: 30px;
        padding: 10px;
        width: 25%;
        color: white;
        height: 51px;
        letter-spacing: 1px;

        &:hover {
            background: #52960B;
            color: white;
        }
    }
}

.CMSDropdown {
    width: 100%;
    height: 3rem;
    color: black !important;
    border-radius: none;
    
    .dropdown-menu {
        width: 100%;

        .dropdown-item {
            width: 100%;
        }
    }

    button {
        width: 100%;
        background-color: white;
        border-radius: inherit;
        height: 100%;
        border: 1px solid gray;
        color: black;

        &:hover {
            background-color: transparent;
            color: black;
        }

        &:active {
            background-color: #003322 !important;
            color: white !important;
        }

        .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
            color: whitw;
            background-color: #003322;
            border-color: gray;
        }
    }
}

.CMSContainer {
    background-color: var(--greenWhite);
    padding: 1rem;
    height: calc(100vh - 12rem);

    .CMSTitle {
        color: var(--secondary-color);
    }
}

.w-fitContent {
    width: fit-content;
}

.br-none {
    border-radius: 0;
}

.m-block {
    margin-block: 1rem;
}

.br-10px {
    border-radius: 10px;
}

.CMSSaveBtn {
    height: fit-content;
    padding: .4rem;
}

.w-100 {
    width: 100%;
}

.gap1 {
    gap: 1rem;
}

.gapPt5 {
    gap: .5rem;
}

.bold {
    font-weight: bold;
}

.addMoreBtn {
    margin-left: auto;
    justify-content: flex-end;
    cursor: pointer;
}

.align-self-end {
    align-self: flex-end;
}

.margin-left-auto {
    margin-left: auto;
}


.AssociateContainer {
    width: 100%;
    background-color: white; 
    padding:.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.ManageHomePgSection {
    width: 100%;
    background-color: var(--greenWhite); 
    padding: 1rem;
}

.AssociateSelectContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.Associate {
    background-color: var(--primary-color);
    padding: .5rem;
    border-radius: 8px;

    .AssociateImgContainer {
        display: flex;
        img {
            object-fit: contain;
            margin-inline: auto;
        }
    }

    .AssociateName {
        margin: 0;
    }
}

.modalContainer {
    background-color: white;
    max-width: 70vw;
    max-height: 70vh;
    margin-inline: auto;
    margin-block: auto;
    min-width: 50vw;
    min-height: 50vh;
    margin-top: 5rem;
    border-radius: 10px;
    position: relative;
}

.bg-none {
    background: transparent;
}

.border-none {
    border: none;
}

.modalContent {
    padding: .5rem;
}



@media screen and (min-width: 576px) and (max-width: 767px) {
    .conact-us-content {
        .contact-us-form-content {
            width: 73%;
            position: absolute;
            left: 13vw;
        }
    }
}

@media screen and (max-width:767px) {
    .filterSection {
        .row {
            gap: 0.7em;
        }

        form {
            .col-md-3 {
                padding-bottom: 15px;
            }
        }
    }

    .ForgotPage .row .col-md-6:last-child {
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .conact-us-content {
        .contact-us-form-content {
            width: 73%;
            position: absolute;
            left: 13vw;
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
    .conact-us-content {
        height: 81vh;

        .contact-us-form-content {
            width: 73%;
            position: absolute;
            left: 13vw;
        }
    }
}

@media screen and (max-width: 575px) {
    .conact-us-content {
        .contact-us-form-content {
            width: 95%;
        }

        .contact-us-btn {
            width: 34%;
        }
    }
}

@media screen and (min-width:1240px) {
    .conact-us-content {
        .contact-us-form-content {
            position: absolute;
            left: 28vw;
        }

    }
}

.LeftSideBarToggleIcon {
    display: none;
}

@media screen and (max-width:1024px) {
    .UserPanel {
        .MainHeader {
            .navbar-toggler {
                display: none;
            }

            .navbar-collapse {
                display: block !important;
            }

            .HeaderProfileSection .dropdown button {
                font-size: 0;

                &:after {
                    font-size: 20px;
                    margin-top: 0px;
                    position: absolute;
                    top: 50%;
                    right: 0;
                }
            }
        }


        .GeneralContainer {
            .LeftSideContainer {
                min-height: auto;
                width: 0;
                border: 0;

                .LeftSideBarToggleIcon {
                    display: block;
                    position: absolute;
                    top: 71px;
                    left: 0;
                    border: 1px solid #ddd;
                    width: 35px;
                    height: 35px;
                    background: var(--secondary-color);
                    color: var(--white);
                    float: left;
                    text-align: center;
                    z-index: 9;
                    border-radius: 0 5px 5px 0;
                    line-height: auto;

                    &.open {
                        left: 300px;
                    }
                }

                .leftSideBarOuter {
                    width: 0;
                    position: fixed;
                    left: 0;
                    top: 71px;
                    overflow: auto;
                    z-index: 9;
                    background: var(--white);
                    height: calc(100vh - 70px);

                    &.open {
                        width: 300px;
                        border-right: 1px solid var(--secondary-color);
                    }
                }
            }

            .RightMainContainer {
                width: 100%;
            }
        }
    }
}


.rdrDays {
    font-size: 0.8vw;
    @media only screen and (max-width: 1024px) {
        font-size: 12px;
    }
}

.daterangepicker {

    td.in-range {
        background-color: var(--primary-color) !important;
    }

    td.active,
    td.active:hover,
    .ranges li.active,
    td.end-date {
        background-color: #52960B !important;
        color: #fff !important;
    }

    .drp-buttons {
        .btn-primary {
            background-color: #0e6d29;
            border-radius: 20px;
        }
    }
}


.DashboardTopItem {
    background-color: $white;
    color: #5B5B98;
    border-radius: 10px;
    padding: 20px 15px;
    margin-bottom: 15px;
    text-align: left;
    border: 1px solid var(--linkText);

    .DashboardTopItemIcon {
        width: 58px;
        height: 58px;
        line-height: 58px;
        background: var(--linkText);
        color: $white;
        font-size: 30px;
        border-radius: 8px;
        text-align: center;
        float: left;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .DashboardTopItemContent {
        float: left;
        width: calc(100% - 68px);
        text-align: left;

        h3 {
            line-height: 1.5;
            letter-spacing: 0.00938em;
            font-size: 1vw;
            color: #5B5B98;
            @media only screen and (min-width: 1024px) {
                font-size: 16px;
            }
            @media only screen and (max-width: 560px) {
                font-size: 5vw;
            }
        }

        h4 {
            line-height: 1.167;
            letter-spacing: -0.01562em;
            font-size: 1.5vw;
            font-weight: 700;
            margin-top: 4px;
            @media only screen and (min-width: 1024px) {
                font-size: 20px;
            }
            @media only screen and (max-width: 560px) {
                font-size: 5vw;
            }
        }
    }

    .DashboardTopItemNotes {
        width: 100%;
        display: block;

        p {
            line-height: 1.5;
            letter-spacing: 0.00938em;
            font-size: 1vw;
            @media only screen and (min-width: 1024px) {
                font-size: 16px;
            }
            @media only screen and (max-width: 560px) {
                font-size: 5vw;
            }
            margin: 0;
        }
    }
}

.GeneralHeader h2 {
    font-size: 1.5vw;
}
.google-login-button {
    border: none;
    background-color: #d6d4d4;
    color: #fff;
    font-size: 12px;
    padding: 10px 22px;
    border-radius: 4px;
}
.facebook-login-button {
    border: none;
    opacity: 0.5;
    background-color: #4267b2;
    color: #fff;
    font-size: 12px;
    padding: 10px 22px;
    border-radius: 4px;
}
.AuthPage,.LoginPage{
    .MuiFormControl-root{
        width: 100%;
        margin-bottom: 20px;
        .MuiFormGroup-row{
            display: block;
            text-align: left;
            .radio-label{
                font-weight: 600;
                margin-bottom: 6px;
                width: 100%;
            }
        }
    }
}
// .social-btn {
//     pointer-events: none;
//     opacity: 0.3;
//     > div > div { 
//         display: inline-block;
//     }
// }

.m-In-a {
    margin-inline: auto;
}

//added-styles

.f-sm {
    font-size: smaller !important;
}

.verifird-badge {
    font-size: 1rem !important;
    margin-top: .2rem;
    color: var(--linkText);
    // margin-left: .2rem;
}

.gapPt3 {
    gap: .15rem
}

.blue {
    color: #4267b2;
}

.underline {
    text-decoration: underline;
}

.clr-6e {
    color: #6e6e6e !important;
}

.dir-col {
    flex-direction: column;

}
.invalid-range-wrapper {
    border: 2px solid red;
    padding: 5px;
    border-radius: 4px;
  }

  .react-calendar-custom{
    width:100%!important;
    border-radius: 10px;
  }

 

.timerangeparent{
    @media only screen and (max-width: 560px) {
        width: 100%;
        position: relative;
        overflow-x: scroll;
        height: 200px;
        overflow-y: hidden;
        margin-bottom: 50px;
        & > div{
            width: 300%;
        }
    }    
}